<template lang="pug">
.relative-position.bg-secondary.b-radius-10( :style="styles" ref="slide" )
    router-link.card.cursor-pointer.relative-posiiton.column.justify-between.no-wrap.font-16.box-border(
        flat
        :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
        :to="city ? `/${city.slug}/product/${data.url}` : `/product/${data.url}`"
        :style="cardStyle")
        .absolute-top-left.justify-center.q-ma-xs.relative-position.row.cold-recipe
            q-icon(
                v-if="data.isPrescription && !data.isErp"
                name="img:rx.svg"
                size="24px"
                color="negative").cold-recipe-icon
                base-tooltip(title="По рецепту")
            q-icon(
                v-if="data.isPrescription && data.isErp"
                name="img:rx_e.svg"
                size="24px"
                color="negative").cold-recipe-icon
                base-tooltip(recipe :offset="[ 10, -100]" title="Жителям Белгородской области теперь доступна доставка курьером выписанных по электронному рецепту лекарств. Узнайте больше об этой услуге «Электоронные рецепты»")
            q-icon(
                v-if="data.cold"
                name="etabl:frost"
                size="24px"
                color="light-blue").cold-recipe-icon
                base-tooltip(title="Особые условия хранения")
            q-icon(
                v-if="data.truCode"
                name="img:fss.svg"
                size="24px").cold-recipe-icon
                base-tooltip(fss :title="fssTitle")

        .q-pa-md.card__img(v-if="!isActivAccessibility").relative-position

            //Шильд акций и товарной активности
            .absolute-bottom-left.justify-center.q-ma-xs.relative-position.cold-recipe(v-if="data.isMarker  && !notAvailable" )
                .q-px-sm.q-py-xs.text-white.font-12.text-weight-regular.b-radius-16(
                    :class="sliderProductDay || (data?.advertismentTitles?.length && data?.advertismentTitles[0]) === 'Товар дня' ? 'bg-blue-10' : 'bg-negative'"
                )
                    div(v-if="data?.titleShort") {{data?.titleShort}}
                    div(v-else-if="data?.advertismentTitles?.length" ) {{data?.advertismentTitles[0]}}
                    div(v-else ) Акция

                    //base-tooltip(
                    //    v-if="data.discount300 || data.discount800"
                    //    title="Акция действительна только в розничной сети аптек «Табле.Точка»"
                    //    :maxWidth="'250px'"
                    //)
            .row.items-center.justify-center.full-height.relative-position
                img.full-height.swiper-lazy(
                    width="100%"
                    style="object-fit: contain; object-position: 50% 50%; max-height: 128px"
                    ref="slideImg"
                    :src="img"
                    :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
                    :alt="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
                    loading="lazy"

                )
        .col-grow.column.q-py-none.q-px-md.justify-between.card__info
            p.column.q-mb-none
                div.font-14.text-weight-regular.line-height-22.text-primary.relative-position
                    span.block#name(style="white-space: pre-line;") {{formattedName(`${data.name} ${data.subtitleShort}`)}}
                        base-tooltip(:title="data.name")
                //span.block.text-primary.text-weight-bold.q-ma-none#name
                //    | {{ data.name }}
                //    base-tooltip(:title="data.name")
                //span.block.font-14.text-primary.q-ma-none(v-if="subtitle")
                //    | {{ data.subtitleShort }}
                //    base-tooltip(:title="data.subtitleShort")
                span#mnn.block.font-14.text-primary.text-weight-regular.q-ma-none.q-mt-xs(v-if="data.mnn?.length")
                    | {{data.mnn[0]}}
                span.block.font-14.text-primary.text-weight-regular.q-ma-none.q-mt-xs(v-else)
                    | &nbsp
                span.row.text-grey.items-center.font-14.q-mt-sm.full-width
                    span {{ data.manufacture }}
                    //span.text-grey.q-ml-xs.font-13 &bull;  {{ data.country }}
                .row.text-grey.q-mb-xs.items-center.font-14.q-mt-md.full-width.no-wrap
                    span.block(v-if="data.isStock") В наличии
                    span.block(v-if="!data.isStock && data.isPreorder") Под заказ c {{ preorderDate }}
                    span.block.text-positive.green-bool.q-mx-xs(v-if="!notAvailable && (data.isPreorder || data.qDs) && (!data.isPrescription  || data.isErp)" )
                    span.block(v-if="(data.isPreorder || data.qDs) && (!data.isPrescription  || data.isErp)")
                        | Доставка
            div.relative-position
                .absolute-bottom-left.q-pb-sm
                    q-icon.delivery_icon(
                        v-if="freeDelivery && (data.qDs || data.isPreorder) && (!data.isPrescription || data.isErp)"
                        :name="data.delivery == 1 ? 'etabl:delivery_one' : 'etabl:delivery_two'"
                        size="24px"
                        :color="data.delivery == 1 ? 'positive' : 'deep-purple-6'")
                        base-tooltip(:title="data.delivery == 1 ? 'Бесплатная доставка' : 'Бесплатная доставка при заказе от 2 товаров'")
                template(v-if="!notAvailable")
                    span.font-14.line-height-16.text-weight-regular.flex.justify-end.text-grey.text-end.q-mb-xs(
                        v-if="data.priceCrossed > 0 && data.isMarker && data.priceCrossed !== data.price"
                        :style="isActivAccessibility ? '' : 'position: relative;'"
                        style="text-decoration: line-through; top: 4px;"
                    )
                        span(v-if="!userAddress?.address_id" ) от&nbsp;
                        | {{ new Intl.NumberFormat('ru-RU').format(data.priceCrossed) }}
                        span &nbsp₽
                    .text-weight-medium.q-ma-none(v-if="count")
                        template(v-if="!data.isStock && !data.isPreorder")
                            span.text-grey
                                | нет в наличии
                    .row.text-positive.text-weight-bold.q-ma-none.items-center.card__price.justify-between.q-mb-sm.q-pl-sm
                        div
                        .row.items-center
                            .sale.q-mr-sm.b-radius-16.outline-negative.flex.items-center(v-if="data.isMarker && salePercent > 0" style="padding: 2px 4px;max-height: 21px")
                                .font-12.line-height-16.text-negative
                                    | {{ salePercent }}%
                            template(v-if="data.isMarker")
                                span.text-weight-bold.font-20.card__price-old.line-height-28
                                    span(v-if="!userAddress?.address_id" ) от&nbsp;
                                    | {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                    span &nbsp₽
                            template(v-else)
                                span.text-weight-bold.font-20.card__price-old.line-height-28(v-if="!!data.price" )
                                    span(v-if="!userAddress?.address_id" ) от&nbsp;
                                    | {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                    span &nbsp₽
        .text-grey-9.text-weight-medium.line-height-20.q-mb-sm.q-pr-md.text-end(v-if="notAvailable && data.analogs")
            | Нет в наличии
        .row.justify-between.q-mb-sm.q-mx-md.items-center(v-if="notAvailable" @click.prevent.stop="subscribe ? unSubscribeProduct() : subscribeProduct()")
            .cursor-pointer.row.items-center.justify-center.q-pl-sm
                q-icon(
                    name="etabl:notification"
                    size="24px"
                    :color="subscribe ? 'positive' : 'grey-7'")
                    base-tooltip(:title="subscribe ? 'Отменить уведомление' : 'Уведомить о поступлении'")
            .font-12.text-grey-9.line-height-20
                | {{ subscribe ? 'Отменить уведомление' : 'Уведомить о поступлении'}}
        .row.justify-between.q-mb-md.q-mx-md.items-center
            .cursor-pointer.row.items-center.justify-center.q-pa-sm.fav-button(
                @click.prevent.default="updateFavourite")
                q-icon(
                    name="favorite_border"
                    size="sm"
                    :color="isFavourite ? 'negative' : 'grey-7'")
                    base-tooltip(title="В избранное")
            //- .font-12.text-weight-medium.text-grey-8(v-if="notAvailable")
            //-     | нет в наличии
            base-btn(
                label="Подобрать аналог"
                v-if="notAvailable && data.analogs"
                color="secondary"
                text_color="positive"
                height="40"
                loading_color="secondary"
                :loading="loading"
                :disable="loading"
                width="144"
                bradius="20"
                border="1px solid #0CA551"
                font_size="14"
                @click.prevent.stop="showAnalogs")
            .text-grey-9.text-weight-medium.line-height-20.q-pr-md.text-end(v-if="notAvailable && !data.analogs")
                | Нет в наличии
            div(v-if="!notAvailable").justify-between.relative-position.q-pa-none.d-none.d-sm-flex.card__busket
                base-btn(label="Только в аптеке"
                    v-if="data.limit === 0 || data.pku"
                    color="accent"
                    text_color="positive"
                    height="40"
                    loading_color="secondary"
                    :loading="loading"
                    :disable="loading"
                    width="126"
                    bradius="20"
                    @click.prevent.stop=""
                )
                template(v-else)
                    base-btn(label="В корзину"
                        v-if="!inCart"
                        height="40"
                        loading_color="secondary"
                        :loading="loading"
                        :disable="loading"
                        width="126"
                        bradius="20"
                        @click.prevent.stop="addToCart"
                    )
                    template(v-else)
                        .row.items-center.q-py-xs.justify-start.cursor-pointer(
                            width="126px"
                            @click.prevent.stop="goToCart"
                        )
                        .row.justify-end.card__counter
                            q-btn.justify-center.items-center.card__button.card__button-min.cursor-pointer(
                                unelevated
                                rounded
                                @click.prevent.stop="setAmount(0)"
                            ) &ndash;
                            .row.justify-center.items-center.card__count {{ amount }}
                            q-btn.justify-center.items-center.card__button.card__button-plus.cursor-pointer(
                                v-if="!preventAmoutUp"
                                unelevated
                                rounded
                                @click.prevent.stop="setAmount(1)"
                            ) +
                            q-btn.justify-center.items-center.card__button.card__button__disable.cursor-pointer(
                                v-else
                                unelevated
                                rounded
                                @click.prevent.stop=""
                            ) +
                                base-tooltip(
                                    style="max-width: 300px;"
                                    title="Количество ограничено"
                                )
</template>

<script>
import { useQuasar } from 'quasar';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BaseBtn from 'components/Base/BaseBtn.vue';
import BaseTooltip from 'components/Base/BaseTooltip.vue';
import BaseSpiner from 'components/Base/BaseSpiner.vue';


export default {
    name: 'PreviewDesktop',
    components: {
        BaseBtn,
        BaseTooltip,
        BaseSpiner,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        subtitle: {
            type: Boolean,
            default: () => true,
        },
        manufacture: {
            type: Boolean,
            default: () => true,
        },
        country: {
            type: Boolean,
            default: () => false,
        },
        count: {
            type: Boolean,
            default: () => true,
        },
        countSlides: {
            type: Number,
        },
        index: {
            type: Number,
        },
        countVisibleSlides: {
            type: Number,
        },
        styles: {
            type: String,
        },
        favPage: {
            type: Boolean,
            default: () => false,
        },
        marketing: {
            type: Boolean,
            default: () => false,
        },
        sliderProductDay: {
            type: Boolean,
            default: () => false,
        },
    },
    setup(props, { emit }) {
        const $q = useQuasar();
        const store = useStore();
        const router = useRouter();
        const amount = ref(1);
        const loading = ref(false);
        const tooltipe = ref(true);
        const amountTimer = ref(null);
        const imgLink = ref(null);
        const slide = ref(null);
        const slideImg = ref(null);
        const subscribe = ref(true);
        const isLocalFavourite = ref(false);

        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const userAddress = computed(() => store.getters['user/USER_ADDRESS']);

        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);
        const isFavourite = computed(() => {
            if (props.favPage) {
                return true;
            }
            if (userAuthorized.value) {
                return isLocalFavourite.value;
            } else {
                const favourites = store.getters['localStorage/FAVOURITES'];
                return favourites?.find((item) => item === props.data.id);
            }
        });

        const cartProducts = computed(() => store.getters['localStorage/BASKET_PRODUCTS']);

        const notAvailable = computed(() => {
            return !!(!props.data.priceNew && !props.data.priceOld && !props.data.minPrice)
                || !!(!props.data.maxPreorderCount && !props.data.maxStockCount);
        });

        const midHeight = computed(() => {
            let height = 194;
            if (!props.manufacture) {
                height -= 30;
            }

            if (!props.subtitle) {
                height -= 30;
            }
            ;
            return height;
        });

        const cardStyle = computed(() => {
            let height = 438;
            if (!props.manufacture) {
                height -= 30;
            }

            if (!props.subtitle) {
                height -= 30;
            }
            ;

            if (!props.count) {
                height -= 30;
            }
            ;

            return `height: ${height}px`;
        });

        const priceFrom = computed(() => {
            if (props.data.maxStockCount && !props.data.maxPreorderCount) {
                return props.data.minPrice;
            }

            return false;
        });

        const inCart = ref(false);

        const img = computed(() => {
            return props.data.previewImage;
        });

        const letsWarning = () => {
            $q.notify({
                color: 'warning',
                message: 'Произошла ошибка! Повторите позднее',
            });
        };

        const updateFavourite = async () => {
            if (isFavourite.value) {
                userAuthorized.value
                    ? deleteProductFromUserFav()
                    : store.commit('localStorage/DELETE_FAVOURITE_PRODUCT', props.data.id);
            } else {
                userAuthorized.value
                    ? addProductToUserFav()
                    : store.commit('localStorage/ADD_FAVOURITE_PRODUCT', props.data.id);
            }
        };

        const deleteProductFromUserFav = async () => {
            try {
                await store.dispatch('user/DELETE_FAVOURITE_PRODUCT', props.data.id);
                await store.commit('user/DELETE_USER_FAVOURITE_PRODUCT', props.data.id);
                isLocalFavourite.value = false;
            } catch (e) {
                console.log(e);
                isLocalFavourite.value = true;
            }
        };

        const addProductToUserFav = async () => {
            try {
                await store.dispatch('user/ADD_FAVOURITE_PRODUCT', props.data.id);
                isLocalFavourite.value = true;
            } catch (e) {
                console.log(e);
                isLocalFavourite.value = false;
            }
        };

        const goToCart = () => {
            router.push(city.value ? `/${city.value.slug}/basket` : '/basket');
        };

        const addToCart = async () => {
            userAuthorized.value
                ? await putProductInCart()
                : await putProductInLocalCart();

            try {
                ym(72234883, 'reachGoal', 'klik-korzina');

                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'add': {
                            'products': [
                                {
                                    'id': props.data.id,
                                    'name': props.data.name + props.data.subtitleShort,
                                    'brand': props.data.manufacture,
                                    'price': props.data.price,
                                },
                            ],
                        },
                    },
                });
            } catch {
            }

            if (props.marketing) {
                try {
                    ym(72234883, 'reachGoal', 'action_product_add');
                } catch {
                }
            }
        };

        // добавление товара в корзину для авторизоаванных
        const putProductInCart = async () => {
            loading.value = true;
            const in_basket = false;

            if (amount.value && amount.value > 0) {
                try {
                    await store.dispatch('basketNew/PUT_PRODUCT_IN_BUSKET', {
                        product: {
                            id: props.data.id,
                            amount: amount.value,
                            checked: true,
                        },
                        in_basket,
                    });

                    inCart.value = true;
                } catch (error) {
                    $q.notify({
                        color: 'negative',
                        message: 'Произошла ошибка. Повторите попытку позже',
                    });
                }
                ;
            }

            loading.value = false;
        };

        const putProductInLocalCart = async (id = props.data.url) => {
            loading.value = true;

            const payload = await store.dispatch('products/GET_PRODUCT_BY_SLUG', { id });
            store.commit('localStorage/ADD_TO_CART', payload);

            inCart.value = true;

            loading.value = false;
        };

        // удаление товара из корзины для авторизоаванных
        const delProductInCart = async () => {
            const in_basket = false;

            try {
                await store.dispatch('basketNew/DELETE_PRODUCT_FROM_BASKET', { id: props.data.id });

                inCart.value = false;

                try {
                    window.dataLayer.push({
                        'ecommerce': {
                            'currencyCode': 'RUB',
                            'remove': {
                                'products': [
                                    {
                                        'id': props.data.id,
                                        'name': props.data.name + props.data.subtitleShort,
                                        'brand': props.data.manufacture,
                                        'price': props.data.price,
                                    },
                                ],
                            },
                        },
                    });
                } catch {
                }
            } catch (error) {
                $q.notify({
                    color: 'negative',
                    message: error.message,
                });
            }
            ;
        };

        const setAmount = async (pm) => {
            if (pm) {
                amount.value++;
            } else {
                if (!(amount.value - 1) || amount.value - 1 < 0) {
                    userAuthorized.value
                        ? await delProductInCart()
                        : store.commit('localStorage/DELETE_ITEMS', props.data.id);
                    amount.value = 1;
                    inCart.value = false;
                    return;
                } else {
                    amount.value--;
                }
                ;
            }
            ;

            if (userAuthorized.value) {
                /**
                 * Если пользователь авторизован
                 * ставим задержку на отправку данных о количестве товара в корзине в 1 сек.
                 */

                if (amountTimer.value) {
                    clearTimeout(amountTimer.value);
                }
                amountTimer.value = setTimeout(await putProductInCart, 300);
            } else {
                // если пользователь НЕ авторизован
                // сохраняем кол-во товара локально в браузере
                store.commit('localStorage/SET_AMOUNT', {
                    id: props.data.id,
                    amount: amount.value,
                });
            }
        };

        const preventAmoutUp = computed(() => {
            if (props.data.limit < 0) {
                return amount.value >= props.data.total_count;
            } else if (props.data.limit == 0 || props.data.pku) {
                return false;
            } else if (props.data.limit > 0) {
                return amount.value >= props.data.limit;
            }
            return false;
        });

        const freeDelivery = computed(() => {
            return props.data.delivery && !props.data.cold;
        });

        const delivery = computed(() => {
            return props.data.isStock && !props.data.cold;
        });

        const userEmail = computed(() => store.getters['user/USER_EMAIL']);

        const userSubscribtions = computed(() => store.getters['user/USER_SUBSCRIPTIONS']);

        const isSubscribe = computed(() => {
            const subscribtions = userSubscribtions.value;
            return subscribtions?.find((item) => item === props.data.id);
        });

        const subscribeProduct = async () => {
            if (!userAuthorized.value) {
                showAuth();
            } else {
                if (!userEmail.value) {
                    $q.notify({
                        color: 'negative',
                        message: 'Для получения уведомлений укажите e-mail',
                    });
                } else {
                    try {
                        await store.dispatch('user/ADD_SUBSCRIPTION', props.data.id);
                        subscribe.value = true;
                        $q.notify({
                            color: 'positive',
                            message: 'Ожидайте уведомление на электронную почту',
                        });
                        try {
                            ym(72234883, 'reachGoal', 'klik-postuplenie');
                        } catch {
                        }
                    } catch (error) {
                        $q.notify({
                            color: 'negative',
                            message: 'Произошла ошибка. Повторите попытку позже',
                        });
                    }
                    ;
                }
            }
        };

        const unSubscribeProduct = async () => {
            try {
                await store.dispatch('user/DELETE_SUBSCRIPTION', props.data.id);
                subscribe.value = false;
            } catch (error) {
                $q.notify({
                    color: 'negative',
                    message: 'Произошла ошибка. Повторите попытку позже',
                });
            }
            ;
        };

        watch(() => props.data, (value) => {
            if (!isSubscribe.value && notAvailable.value) {
                subscribe.value = false;
            }
        });

        // const salePercent = computed(() => {
        //     return Math.ceil((1 - (props.data.priceNew / props.data.priceOld)) * 100);
        // });
        // const salePercent = computed(() => {
        //     return Math.ceil(((props.data.priceCrossed - props.data.price) / props.data.priceCrossed) * 100);
        // });
        const salePercent = computed(() => {
            if (props.data.priceCrossed) {
                const percent = ((props.data.priceCrossed - props.data.price) / props.data.priceCrossed) * 100;
                const decimalPart = Math.floor((percent % 1) * 10);

                if (decimalPart > 5) {
                    return `${Math.ceil(percent)}`;
                } else {
                    return `${Math.floor(percent)}`;
                }
            } else {
                return false;
            }
        });

        const checkLocalBasket = () => {
            let localProd = cartProducts.value?.find((item) => item.id === props.data.id);

            if (localProd) {
                amount.value = localProd.amount;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
            ;
        };

        const checkProdInBasket = () => {
            if (props.data.inBasket) {
                amount.value = props.data.inBasket;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
        };

        const fssTitle = computed(() => {
            return 'Этот товар можно оплатить в аптеках сети партнера "Табле.Точка" с помощью электронного сертификата ФСС, картой «МИР».';
        });

        const fastBuy = async () => {
            if (!inCart.value) {
                userAuthorized.value ? await putProductInCart() : await putProductInLocalCart();
            }

            goToCart();
        };

        const showAnalogs = async () => {
            await store.dispatch('products/GET_PRODUCT_ANALOGS', { slug: props.data.url });
            store.commit('products/SET_CURRENT_PRODUCT_ANALOGS', `${props.data.name} ${props.data.subtitleShort}`);
            if (process.env.CLIENT) {
                // window._paq.push(['trackGoal', 6]);
            }
            ;
            emit('showAnalogsList');
        };

        const preorderDate = computed(() => {
            let date = new Date(props.data.nearestDate);

            return date.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
            });
        });

        const formattedName = (text) => {
            if (!text) return '';
            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                if (lastLine && lastLine.length + word.length + 1 > 23) {
                    acc.push(word);
                } else {
                    if (lastLine) {
                        acc[acc.length - 1] += ' ' + word;
                    } else {
                        acc.push(word);
                    }
                }
                return acc;
            }, []);

            if (lines.length > 2) {
                lines.length = 3;
                lines[2] += '...';
            }

            return lines.join(' ');
        };
        const formattedMnn = (text) => {
            if (!text) return '';

            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                if (lastLine && lastLine.length + word.length + 1 > 24) {
                    acc.push(word);
                } else {
                    if (lastLine) {
                        acc[acc.length - 1] += ' ' + word;
                    } else {
                        acc.push(word);
                    }
                }
                return acc;
            }, []);

            if (lines.length > 2) {
                lines.length = 2;
                lines[1] += '...';
            }

            return lines.join(' ');
        };


        onMounted(() => {
            if (!userAuthorized.value) {
                checkLocalBasket();
            } else {
                checkProdInBasket();
            }

            if (props.data.isFavourite)
                isLocalFavourite.value = true;

            emit('setSlideHeight', slide.value.clientHeight);

            if (!isSubscribe.value && notAvailable.value) {
                subscribe.value = false;
            }
            emit('previewReady');
        });

        return {
            slide,
            inCart,
            slideImg,
            cardStyle,
            isFavourite,
            updateFavourite,
            addToCart,
            img,
            notAvailable,
            priceFrom,
            goToCart,
            setAmount,
            amount,
            loading,
            preventAmoutUp,
            delivery,
            freeDelivery,
            userSubscribtions,
            isSubscribe,
            subscribeProduct,
            unSubscribeProduct,
            subscribe,
            userEmail,
            salePercent,
            midHeight,
            deleteProductFromUserFav,
            isLocalFavourite,
            addProductToUserFav,
            userAuthorized,
            checkLocalBasket,
            checkProdInBasket,
            fssTitle,
            fastBuy,
            city,
            isActivAccessibility,
            showAnalogs,
            userAddress,
            preorderDate,
            formattedName,
            formattedMnn,
        };
    },
};
</script>

<style lang="scss" scoped>
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid $positive;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.card {
    width: 100%;
    border: 1px solid #0000001f;
    //transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1) !important;
    text-decoration: none;
    border-radius: 10px;

    @media screen and (max-width: $breakpoint-mobile) {
        border: none;
    }

    .green-bool {
        background: $positive;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        display: block;
    }

    span.block#name {
        display: block !important;
        line-height: 22px;
        height: 66px;
        overflow: hidden;
        white-space: pre-line;
        max-width: 186px;
        text-overflow: ellipsis;
        position: relative;
    }

    span#mnn {
        display: block !important;
        line-height: 22px;
        height: 22px;
        overflow: hidden;
        white-space: pre-line;
        max-width: 158px;
        text-overflow: ellipsis;
        position: relative;
    }


    p {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media screen and (max-width: $breakpoint-mobile) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &__img {
        height: 160px;
        width: 100%;

    }

    &__info {
        flex-grow: 1;
        max-width: 100%;
        @media (max-width: 1280px) {
            flex-grow: 0;
        }

        &-manufacturer {
            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 7px !important;
            }
        }
    }

    &__amount {
        font: {
            size: 14px;
            weight: 600;
        }

        & > span {
            text-align: center;
            margin-top: -2px;
        }

        @media (max-width: 1280px) {
            border-radius: 10px;
        }
    }

    &__counter {
        width: 126px;
        max-height: 100%;
        border-radius: 20px;
        overflow: hidden;
        color: $primary;
        top: 0px;
        right: 0px;

        font: {
            size: 12px;
            weight: 500;
        }
        @media (max-width: 960px) {
            margin-right: 0px;
        }
    }

    &__count {
        width: 42px;
        height: 40px;
        background-color: $app-color-background;
    }

    &__button {
        width: 42px;
        min-height: 100%;
        background-color: $accent;
        color: $primary;
        text-transform: none;
        border-radius: 0;
        padding: 0 !important;

        font: {
            size: 16px;
            weight: 400;
        }

        &__disable {
            color: $medium-gray;
        }
    }

    &__price {
        @media screen and (max-width: $breakpoint-mobile) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            padding-bottom: 6px;
            font: {
                size: 14px !important;
            }
        }

        &-from {
            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 18px !important
            }
        }

        &-old {
            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 18px !important
            }
        }

        &-new {
            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 1px;
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05) !important;
        border-radius: 8px;
        margin-bottom: 5px;
        align-items: flex-start;
    }
}

.card:hover {
    border-color: $positive;
}

.card :deep(.q-btn) {
    font-size: 14px;
    z-index: 2;
}

.box-border {
    box-sizing: border-box;

    @media screen and (max-width: $breakpoint-mobile) {
        position: relative;
    }
}

.cold-recipe {
    padding: 7px;
    z-index: 999;

    &-icon {
        margin-right: 7px;
    }
}

.delivery {
    padding: 7px;
    z-index: 999;

    &-icon {
        margin-left: 7px;
    }
}

.q-px-lg {
    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.q-card__actions {
    padding: 0;
}

.card__info {
    font-size: 18px;
    @media (max-width: 1280px) {
        font-size: 14px
    }
}

.card__price {
    font-size: 18px;
    @media (max-width: 1280px) {
        font-size: 14px
    }
}

.card__amount {
    font-size: 14px;

    @media (max-width: 1280px) {
        font-size: 12px;
    }
}

.fav-button {
    z-index: 999;
    transition: $main-transition;
    height: 40px;
    border-radius: 20px;
    width: 40px;
    background: $grey-5;

    &:hover {
        transform: scale(1.1);
    }
}

.delivery_icon {
    margin-right: 24px;

    @media screen and (max-width: 1280px) {
        margin-right: 0;
    }
}

</style>
